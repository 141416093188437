<template>
     <div align="center">
        <br><br>

        <div class="row justify-center">
            <div class="col-12 col-md-10">
                <QQButton label="Fatture attesa emissione" color="blue-grey" icon="mdi-alarm" size="xl" @click.native="onFattureNonEmesse()"/>
                <QQButton label="Fatture emesse" color="blue-grey" icon="mdi-clipboard-check-multiple-outline" size="xl" @click.native="onFattureEmesse()"/>
                <QQButton label="Fatture rifiutate" color="blue-grey" icon="mdi-tow-truck" size="xl" @click.native="onFattureRifiutate()"/>
            </div>

            <br><br><br><br><br>
        </div>

    </div>
</template>

<script>
    import QQButton from "@/components/QQButton.vue";
    //import policy from "@/libs/policy";
    //import commonLib from "@/libs/commonLib";
    //import {mapFields} from "vuex-map-fields"
    //import { mapState, mapActions } from "vuex";

    export default {
        name: "MenuGestioneFatture",
        data() {
            return {
                annotazione: ""
            }
        },
        components: {
            QQButton
        },
        methods: {
            onFattureNonEmesse() {
                this.$router.push({name : "Fatture.TabellaFattureNonEmesse"});
            },
            onFattureEmesse() {
                this.$router.push({name : "Fatture.TabellaFattureEmesse"});
            },
            onFattureRifiutate() {
                this.$router.push({name : "Fatture.TabellaFattureErrore"});
            }
        }
    }
</script>